<template>
  <section class="w-full">
    <header-register
      :showButtonView="(previewButtonIsVisible = false)"
      @register="handleActivePageOperation"
      >{{ $t("sectionProviders.titleProviders") }}
    </header-register>
    <main class="w-full">
      <ManageSuppliersEdit />
    </main>
  </section>
</template>

<script type="module">
import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ManageSuppliersEdit from "@/components/fornecedores/components/edit/ManageSuppliersEdit.vue";

export default {
  name: "ProvidersEdit",
  components: {
    HeaderRegister,
    ManageSuppliersEdit,
  },
};
</script>
