<template>
  <section>
    <b-form>
      <!-- Personal Data -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-6">
              <input-required
                v-model="value.Nome"
                :label="$t('sectionProviders.personalData.name')"
                :rules="{ required:true,max:50}"
                max="50"
              />
            </div>
            <div class="col-span-12 sm:col-span-6">
              <input-required
                v-model="value.NomeFantasia"
                :rules="{ required:true,max:50}"
                :label="$t('sectionProviders.personalData.fantasyName')"
                max="50"
              />
            </div>
          </div>
        </div>

        <div class="col-span-8 border-solid border-b border-gray-300 pb-2">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-6">
              <input-required
                v-model="value.CpfCnpj"
                :label="$t('sectionProviders.personalData.document')"
                :rules="{required:true, min:14, max:18, cpfCnpj:true}"
                :mask="['###.###.###-##', '##.###.###/####-##']"
                max="18"
              />
            </div>
            <div class="col-span-12 sm:col-span-6">
              <input-required
                v-model="value.InscEstadual"
                :rules="{ required:true, inscEstadual:siglaUf}"
                :label="$t('sectionProviders.personalData.stateRegistration')"
                max="20"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Address Data -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4 mt-3">
        <div class="col-span-8 border-solid border-b border-gray-300">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 xl:col-span-3">
              <input-default
                v-model="value.endereco.Cep"
                :rules="{ min:9}"
                :label="$t('sectionProviders.addressData.zipCode')"
                mask="#####-###"
                max="9"
              />
            </div>
            <div class="col-span-12 xl:col-span-7">
              <input-default
                v-model="value.endereco.Rua"
                max="50"
                :rules="{ max:50}"
                :label="$t('sectionProviders.addressData.road')"
              />
            </div>
            <div class="col-span-12 xl:col-span-2">
              <input-default
                v-model="value.endereco.Numero"
                :rules="{ max:7}"
                max="7"
                :label="$t('sectionProviders.addressData.number')"
                placeholder="Nº"
              />
            </div>
               <div class="col-span-12 lg:col-span-4">
                <Dropdown 
                        :Items="dropdownItemsEstado"
                        required="required"
                        v-model="value.endereco.EstadoId"
                        :valueDefault="$t('sectionProviders.addressData.state')"
                        :label="$t('sectionProviders.addressData.state')"
                        @SiglaUf = "siglaUf = $event"
                      @ItemSelectId="DropdownSelected($event,'estado')">
                </Dropdown>
              </div>
               <div class="col-span-12 lg:col-span-4">
                <Dropdown 
                        :Items="dropdownItemsCidade"
                        v-model="value.endereco.CidadeId"
                        :valueDefault="$t('sectionProviders.addressData.city')"
                        :label="$t('sectionProviders.addressData.city')"
                        @ItemSelectId="DropdownSelected($event,'cidade')">
                </Dropdown>
              </div>
               <div class="col-span-12 lg:col-span-3">
                <Dropdown
                        :Items="dropdownItemsBairro"
                        v-model="value.endereco.BairroId"
                        :valueDefault="$t('sectionProviders.addressData.district')"
                        :label="$t('sectionProviders.addressData.district')">
                </Dropdown>
              </div>
              <div class="col-span-12 xl:col-span-7">
              <input-default
                v-model="value.endereco.Complemento"
                :rules="{ max:20}"
                max="20"
                :label="$t('sectionProviders.addressData.complemento')"
              />
            </div>
          </div>
        </div>
        <div class="col-span-8"></div>
      </div>
      <!-- Contact Data -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8 border-solid border-b border-gray-300">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 lg:col-span-2">
              <input-default
                v-model="value.Telefone[0].ddd"
                :label="$t('sectionProviders.contactData.ddd1')"
                placeholder="(99)"
                :rules="{ max:5}"
                mask="(##)"
                max="5"
              />
            </div>
            <div class="col-span-12 lg:col-span-4">
              <input-default
                v-model="value.Telefone[0].numero"
                :label="$t('sectionProviders.contactData.phone')"
                placeholder="9999-9999"
                mask="####-####"
                :rules="{ max:9}"
                max="9"
              />
            </div>
            <div class="col-span-12 lg:col-span-2">
              <input-default
                v-model="value.Telefone[1].ddd"
                :label="$t('sectionProviders.contactData.ddd2')"
                placeholder="(99)"
                :rules="{ max:5}"
                mask="(##)"
                max="5"
              />
            </div>
            <div class="col-span-12 lg:col-span-4">
              <input-default
                v-model="value.Telefone[1].numero"
                :label="$t('sectionProviders.contactData.cellPhone')"
                placeholder="9 9999-9999"
                mask="# ####-####"
                :rules="{ max:11}"
                max="11"
              />
            </div>
          </div>
        </div>
        <div class="col-span-8">
          <input-default
            v-model="value.Email"
            :rules="{ email:true,max:60}"
            max="60"
            :label="$t('sectionProviders.contactData.email')"
          />
        </div>
      </div>
    </b-form>
  </section>
</template>

<script>
import { required } from "@validations";
import InputRequired from "@core/components/commons/inputs/InputRequired";
import InputDefault from "@core/components/commons/inputs/InputDefault";
import Dropdown from "@/@core/components/commons/selectDropdown/Dropdown.vue";

import { BForm } from "bootstrap-vue";

export default {
  components: {
    BForm,
    InputRequired,
    InputDefault,
    Dropdown,
  },
  props: {
    value: {
      type: Object,
    }
  },
  data () {
    return {
      dropdownItemsEstado:[],
      dropdownItemsBairro:[],
      dropdownItemsCidade:[],
      required,
      siglaUf:null,
    };
  },
  created () {
    this.ObterSeletorEstado();
  },
  methods: {
     ObterSeletorBairro(cidadeId) {
      this.$http({
        url: `/bairro/obter-seletor-bairro`,
        params: { idCidade: `${cidadeId}` },
        method: "GET"
      }).then((res) => {
          this.dropdownItemsBairro = res.data;
        })
    },
    ObterSeletorEstado() {
      this.$http({
        url: `/estado/obter-seletor-estados`,
        method: "GET"
      }).then((res) => {
        this.dropdownItemsEstado = res.data;
        })
    },
    ObterSeletorCidade(estadoId) {
      this.$http({
        url: `/cidade/obter-seletor-cidades-por-estado/${estadoId}`,
        method: "GET"
      }).then((res) => {
          this.dropdownItemsCidade = res.data;
        })
    },
    DropdownSelected (item, tipo) {
      if(tipo == 'estado') {
        this.ObterSeletorCidade(item);
      } else if (tipo == 'cidade') {
        this.ObterSeletorBairro(item);
      }
    },
  },
  watch: {
    "value.endereco.Cep": function (value) {
      if (value.length == 9) {
        this.$http({
          url: `/Endereco/cep`,
          params: { CEP: value },
          method: "POST"
        }).then((res) => {
          if (res.data.ufCadastrado) {
            this.dropdownItemsEstado?.push({id: res.data.ufId, descricao: res.data.uf})
          }
          this.value.endereco.Rua = res.data.logradouro;
          this.value.endereco.EstadoId = res.data.ufId;
          this.value.endereco.CidadeId = res.data.cidadeId;
          this.value.endereco.BairroId = res.data.bairroId;
        })
      }
    }
  },
}
</script>
