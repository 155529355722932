<template>
<ValidationObserver ref="observer" v-slot="{ validate  }">
  <b-form @submit.stop.prevent="validate().then(Edit)">
  <div class="w-full flex flex-col justify-between">
    <section class="-mt-4">
      <b-tabs class="w-full">
        <b-tab title="Geral">
          <GeneralSupplierInformation
            class="mt-8"
            v-model="viewModel"
          />
        </b-tab>
        <b-tab title="Complemento">
          <SupplementaryInformationSupplier
            class="mt-8"
            v-model="viewModel"
          />
        </b-tab>
      </b-tabs>
    </section>
    <div class="flex">
      <span class="text-red-600">Os campos com * são de preenchimento obrigatório</span>
    </div>
    <footer class="w-full flex items-center justify-start gap-5 my-10">
      <Button
        class="bg-green-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-green-600 transition-all duration-300"
        buttonText="Confirmar"
        type="submit"
      />
      <Button
        class="bg-red-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-red-600 transition-all duration-300"
        buttonText="Cancelar"
        :onClick="redirectUrl"
      />
    </footer>
    <Modal
      v-if="modalIsOpen"
      :modalIsOpen.sync="modalIsOpen"
      :closeModal="closeModal"
      :modalText="$t('commons.modalTextEdit')"
    />
     <Modal
      v-if="modalError"
      :modalIsOpen.sync="modalError"
      :closeModal="closeModal"
      :modalText="$t('commons.modalErrorCreate')"
      />
      <Modal
      v-if="modalErroSalvar"
      :modalIsOpen.sync="modalErroSalvar"
      :closeModal="closeModal"
      :modalText="$t('commons.modalErroSalvar')"
      />
  </div>
</b-form>
</ValidationObserver>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";

import GeneralSupplierInformation from "./GeneralSupplierInformation";
import SupplementaryInformationSupplier from "./SupplementaryInformationSupplier";
import Button from "@core/components/commons/button/Button";
import Modal from "@core/components/commons/modal/Modal";
import { BForm } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";

export default {
  name: "ManageSuppliersEdit",
  components: {
    BTabs,
    BTab,
    BForm,
    ValidationObserver,
    GeneralSupplierInformation,
    SupplementaryInformationSupplier,
    Button,
    Modal,
  },
  data () {
    return {
      modalIsOpen: false,
      modalError: false,
      modalErroSalvar: false,
      modalErrorMessage: null,
      viewModel: {
        Id: null,
        Pessoaid: null,
        Nome: null,
        NomeFantasia: null,
        CpfCnpj: null,
        InscEstadual: null,
        Email: null,
        Telefone: [],
        //Endereço
        endereco: {
          Cep: null,
          Rua: null,
          Numero: null,
          Complemento: null,
          BairroId: null,
          CidadeId: null,
          EstadoId: null,
        },
        Banco: {
          BancoId: null,
          NomeDoBanco: null,
          Agencia: null,
          ContaCorrente: null,
        },
        Documentacao: {
          ResponsavelTecnico: null,
          AlvaraSanitario: null,
          AutEspecial: null,
          LicencaMapa: null,
          AutFuncionamento: null,
        },
        Farmacia: {
          CadastroFarmacia: null,
          Host: null,
          UsuarioFTP: null,
          Senha: null,
          Conta: null,
          ValorMinimo: null,
          FormaDePagamento: null,
          PrevisaoDeEntrega: null,
          Frete: null,
          Observacoes: null,
        },
      }
    };
  },
   created () {
    this.RecuperarUser(this.$router.currentRoute.params.id);
  },
  methods: {
    openModal () {
      this.modalIsOpen = true;
    },
    closeModal (event) {
      if (event.target === event.currentTarget) {
        this.modalIsOpen = false;
        this.modalError = false;
        this.modalErroSalvar = false;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    RecuperarUser(id)
      {
      this.$http({
          url: `/fornecedor/obter/${id}`,
          method: "Get"
        }).then((response) => {
          this.viewModel = {
            Nome: response.data.nome,
            Id: response.data.id,
            Pessoaid: response.data.pessoaid,
            NomeFantasia: response.data.nomeFantasia,
            CpfCnpj: response.data.cpfCnpj,
            InscEstadual: response.data.inscEstadual,
            Email: response.data.email,
            Telefone: response.data.telefone,
            //Endereço
            endereco: {
              Cep: response.data.endereco?.cep,
              Rua: response.data.endereco?.rua,
              Numero: response.data.endereco?.numero,
              Complemento: response.data.endereco?.complemento,
              BairroId: response.data.endereco?.bairroId,
              CidadeId: response.data.endereco?.cidadeId,
              EstadoId: response.data.endereco?.estadoId,
            },
            Banco: {
              BancoId: response.data.dadosbancarios?.banco,
              NomeDoBanco: response.data.dadosbancarios?.nomeDoBanco,
              Agencia: response.data.dadosbancarios?.agencia,
              ContaCorrente: response.data.dadosbancarios?.contaCorrente,
            },
            Documentacao: {
              ResponsavelTecnico: response.data.documentos?.responsavelTecnico,
              AlvaraSanitario: response.data.documentos?.alvaraSanitario,
              AutEspecial: response.data.documentos?.autEspecial,
              LicencaMapa: response.data.documentos?.licencaMapa,
              AutFuncionamento: response.data.documentos?.autFuncionamento,
            },
            Farmacia: {
              CadastroFarmacia: response.data.cadastrofarma?.cadastroFarmacia,
              Host: response.data.cadastrofarma?.hostFTP,
              UsuarioFTP: response.data.cadastrofarma?.usuarioFTP,
              Senha: response.data.cadastrofarma?.senhaFTP,
              Conta: response.data.cadastrofarma?.conta,
              ValorMinimo: response.data.cadastrofarma?.valorMinimoPedido,
              FormaDePagamento: response.data.cadastrofarma?.formaPagamento,
              PrevisaoDeEntrega: response.data.cadastrofarma?.previsaoEntrega?.toString(),
              Frete: response.data.cadastrofarma?.frete,
              Observacoes: response.data.cadastrofarma?.observacao,
            },
          }
          //validando o telefone para não quebrar depois no componente filho
          this.viewModel.Telefone = [];
            var telefone = response.data.telefone?.filter((item) => item.numero?.length <= 8);
            this.viewModel.Telefone[0] = {
                ddd: telefone[0]?.ddd || null,
                numero: telefone[0]?.numero || null
            }

            var celular = response.data.telefone?.filter((item) => item.numero?.length >= 9);
            this.viewModel.Telefone[1] = {
                ddd: celular[0]?.ddd || null,
                numero: celular[0]?.numero || null
          }
        })
    },
   async Edit () {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.modalError = true;
        return
      }
        this.RemoverMascaras();
        this.$http({
          url: `/fornecedor/editar`,
          data: this.viewModel,
          params: { FornecedorId: this.viewModel.Id, PessoaId: this.viewModel.Pessoaid },
          method: "POST"
        }).then(() => {
          this.modalIsOpen = true;
          setTimeout(() => this.$router.push({ name: "fornecedores" }), 3000);
        }).catch(() => this.modalErroSalvar = true)
    },
    RemoverMascaras () {
      this.viewModel.Telefone = this.viewModel.Telefone?.filter((item) =>  item?.ddd != null && item?.ddd != "" &&  item?.numero != null && item?.numero != "");
      if (this.viewModel.Telefone?.length == 0) this.viewModel.Telefone = null;
      if (this.viewModel.Farmacia.ValorMinimo == null || this.viewModel.Farmacia.ValorMinimo == "") this.viewModel.Farmacia.ValorMinimo = 0;
      if (this.viewModel.endereco.Cep?.length == 0) this.viewModel.endereco.Cep = null;
       for (let index = 0; index < this.viewModel.Telefone?.length; index++) {
            this.viewModel.Telefone[index].ddd =this.viewModel.Telefone[index].ddd.replace(/[^0-9,]*/g, '');
            this.viewModel.Telefone[index].numero =this.viewModel.Telefone[index].numero.replace(/[^0-9,]*/g, '');
      }
      this.viewModel.CpfCnpj = this.viewModel.CpfCnpj.replace(/[^0-9,]*/g, '');
      this.viewModel.endereco.Cep = this.viewModel.endereco.Cep?.replace(/[^0-9,]*/g, '');
       this.viewModel.Banco.Agencia = this.viewModel.Banco.Agencia?.replace(/[^a-z0-9]/gi, '');
      this.viewModel.Banco.ContaCorrente = this.viewModel.Banco?.ContaCorrente?.replace(/[^a-z0-9]/gi, '');
    },
    redirectUrl() {
      return this.$router.push("/fornecedores");
    },
    }
  }
</script>

<style></style>
