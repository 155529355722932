<template>
  <section>
    <b-form>
      <!-- Bank Account -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-3">
            <Dropdown 
                      :Items="dropdownItemsBanco"
                      v-model="value.Banco.BancoId"
                      :valueDefault="$t('sectionProviders.bankAccount.bank')"
                      :label="$t('sectionProviders.bankAccount.bank')">
            </Dropdown>
            </div>
          </div>
        </div>

        <div class="col-span-8 border-solid border-b border-gray-300 pb-2">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-3">
              <input-default
                v-model="value.Banco.Agencia"
                max="6"
                :rules="{max:6}"
                :label="$t('sectionProviders.bankAccount.agency')"
                :mask="['XXX', 'XXX-X', 'XXX-XX']"
              />
            </div>
            <div class="col-span-12 sm:col-span-9">
              <input-default
                v-model="value.Banco.ContaCorrente"
                :rules="{max:15}"
                max="15"
                :label="$t('sectionProviders.bankAccount.checkingAccount')"
                :mask="['XXX-X', 'XXXX-X', 'XXXXX-X', 'XXXXXX-X']"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Complementary Info -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4 mt-3">
        <div class="col-span-8 border-solid border-b border-gray-300">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <input-default
                v-model="value.Documentacao.ResponsavelTecnico"
                :rules="{max:255}"
                max="255"
                :label="
                  $t('sectionProviders.complementaryInfo.technicalManager')
                "
              />
            </div>
            <div class="col-span-12 xl:col-span-4">
              <input-default
                :rules="{max:10}"
                v-model="value.Documentacao.AlvaraSanitario"
                max="10"
                :label="$t('sectionProviders.complementaryInfo.healthPermit')"
              />
            </div>
            <div class="col-span-12 xl:col-span-4">
              <input-default
                v-model="value.Documentacao.AutFuncionamento"
                :rules="{max:10}"
                max="10"
                :label="
                  $t('sectionProviders.complementaryInfo.operatingPermit')
                "
                placeholder="Nº"
              />
            </div>
            <div class="col-span-12 lg:col-span-4">
              <input-default
                v-model="value.Documentacao.AutEspecial"
                :rules="{max:10}"
                max="10"
                :label="$t('sectionProviders.complementaryInfo.specialPermit')"
              />
            </div>
            <div class="col-span-12 lg:col-span-8">
              <input-default
                v-model="value.Documentacao.LicencaMapa"
                :rules="{max:50}"
                max="50"
                :label="$t('sectionProviders.complementaryInfo.mapLicense')"
              />
            </div>
          </div>
        </div>
        <div class="col-span-8"></div>
      </div>
      <!-- Pharma Register -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8 border-solid border-b border-gray-300">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <input-default
                v-model="value.Farmacia.CadastroFarmacia"
                :rules="{max:20}"
                max="20"
                :label="
                  $t('sectionProviders.pharmaRegister.pharmacyRegistration')
                "
              />
            </div>
            <div class="col-span-12 lg:col-span-4">
              <input-default
                v-model="value.Farmacia.Host"
                :rules="{max:70}"
                max="70"
                :label="$t('sectionProviders.pharmaRegister.host')"
              />
            </div>
            <div class="col-span-12 lg:col-span-4">
              <input-default
                v-model="value.Farmacia.UsuarioFTP"
                :rules="{max:15}"
                max="15"
                :label="$t('sectionProviders.pharmaRegister.user')"
              />
            </div>
            <div class="col-span-12 lg:col-span-4">
              <input-default
                v-model="value.Farmacia.Senha"
                :rules="{max:15}"
                max="15"
                :label="$t('sectionProviders.pharmaRegister.password')"
                placeholder="********"
              />
            </div>
            <div class="col-span-12 lg:col-span-4">
            <Dropdown :Items="dropdownItems"
                      v-model="value.Farmacia.Conta"
                      :valueDefault="$t('sectionProviders.pharmaRegister.account')"
                      :label="$t('sectionProviders.pharmaRegister.account')">
            </Dropdown>
            </div>
            <div class="col-span-12 lg:col-span-4">
              <input-default
                v-model="value.Farmacia.ValorMinimo"
                :label="$t('sectionProviders.pharmaRegister.minimumValue')"
                type="number"
                :rules="{double:2,max:10,positive:true}"
              />
            </div>
            <div class="col-span-12 lg:col-span-8">
              <input-default
                :rules="{max:100}"
                max="100"
                v-model="value.Farmacia.FormaDePagamento"
                :label="$t('sectionProviders.pharmaRegister.formPayment')"
              />
            </div>
            <div class="col-span-12 lg:col-span-4">
              <input-default
                v-model="value.Farmacia.PrevisaoDeEntrega"
                type="number"
                :rules="{integer:true,max:3,positive:true}"
                :label="$t('sectionProviders.pharmaRegister.deliveryForecast')"
              />
            </div>
            <div class="col-span-12 lg:col-span-8">
              <input-default
                v-model="value.Farmacia.Frete"
                :rules="{max:100}"
                max="100"
                :label="$t('sectionProviders.pharmaRegister.shipping')"
              />
            </div>
          </div>
        </div>
        <div class="col-span-8">
          <input-default
            v-model="value.Farmacia.Observacoes"
            :rules="{max:255}"
            max="255"
            :label="$t('sectionProviders.pharmaRegister.comments')"
          />
        </div>
      </div>
    </b-form>
  </section>
</template>

<script type="module">
import InputDefault from "@core/components/commons/inputs/InputDefault";
import Dropdown from "@/@core/components/commons/selectDropdown/Dropdown.vue";

import { required } from "@validations";
import { BForm } from "bootstrap-vue";

export default {
  name: "SupplementaryInformationSupplier",
  components: {
    BForm,
    InputDefault,
    Dropdown,
  },
  props: {
     value: {
      type: Object,
    },
  },
  data() {
    return {
      dropdownItems: [],
      dropdownItemsBanco: [],
      required,
    }
  },
  created () { 
     this.ObterSeletorPlanoContas();
    this.ObterSeletorBanco();
  },
    methods: {
    ObterSeletorPlanoContas() {
      this.$http({
        url: "/planoConta/obter-select-plano-conta",
        method: "GET"
      }).then((res) => {
        this.dropdownItems = res.data;
      })
    },
    ObterSeletorBanco () {
      this.$http({
        url: "/banco/obter-seletor-banco",
        method: "GET"
      }).then((res) => {
         this.dropdownItemsBanco = res.data;
      })
      },
    DropdownSelected (item, tipo) {
      if (tipo == 'banco') { 
        this.value.Banco.BancoId = item;
      } else if(tipo == 'conta') {
        this.value.Farmacia.Conta = item;
      }
    }
  }
};
</script>
